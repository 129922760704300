import React, { useContext } from 'react'
import { AnalizedTracks } from './Analyzed'
import { CSSTransition } from 'react-transition-group'
import './animation.css'

import Tune from './Tune'

function Tunes(props) {

    const [tracks] = useContext(AnalizedTracks)

    return (
        <div id="tracks">
            <CSSTransition in={tracks.startTween} timeout={1000} classNames="tween" onEntered={props.finishNextTrack}>
                <div><Tune targetTrackKey="currentSong" /></div>
            </CSSTransition>
            <CSSTransition in={tracks.startTween} timeout={1000} classNames="tween">
                <div><Tune targetTrackKey="nextSong" /></div>
            </CSSTransition>
        </div>
    )
}

export default Tunes