import React, { useState, useEffect, useRef } from 'react'
import Tunes from './Tunes'
import music from '../../img/icon_music.svg'
import '../../css/style_track.scss'

export const AnalizedTracks = React.createContext()

const apigClientFactory = require('aws-api-gateway-client').default

function Analyzed() {

	const [tracks, setTracks] = useState({
		trackAmount: 0,
		tunes: [], 
		currentSong: null, 
		nextSong: null, 
		startTween: false, 
		count: 0
	})

	const containerRef = useRef(null)
	const intervalId = useRef(-1)
	const currentTrackIndex = useRef(0)
	const initialized = useRef(false)

	const handleScroll = event => {
		if (isInViewport(0)) {
			startNextTrackInterval()
		}
		else {
			stopNextTrackInterval()
		}
	}

	useEffect(() => {
		window.removeEventListener('scroll', handleScroll)
		window.addEventListener('scroll', handleScroll)
		if (!initialized.current) {
			initialized.current = true
			let AWS = window.AWS
			AWS.config.region = 'ap-northeast-1'
			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId: 'ap-northeast-1:9a73997e-c377-4c3e-ab2c-1c466ccfd141'
			})
			AWS.config.credentials.get(err => {
				if (!err) {
					const apigClient = apigClientFactory.newClient({
						invokeUrl: 'https://hbxd28fgk8.execute-api.ap-northeast-1.amazonaws.com/production',
						region: 'ap-northeast-1',
						accessKey: AWS.config.credentials.accessKeyId,
						secretKey: AWS.config.credentials.secretAccessKey,
						sessionToken: AWS.config.credentials.sessionToken 
					  });

					let lang = document.documentElement.lang === "ja" ? "JP" : "US"

					apigClient.invokeApi({}, `/songs`, 'GET',  {queryParams: {
						"country": lang 
					}}, {})
						.then(result => {
							console.log(result)
							showLatestTrack(result)
						}).catch(e => {
							console.log(e)
						})
				}
			})
		}
		else {
			handleScroll()
		}
		return () => {
			stopNextTrackInterval()
			window.removeEventListener('scroll', handleScroll)
		}
	})

	const showLatestTrack = result => {
	
		const obj = JSON.parse(result.data.body)
		if (!obj) return;
		let arr = obj.song
		if (!arr) return;
		for (let i = arr.length - 1; i > 0; i--) {
			const r = Math.floor(Math.random() * (i + 1))
			const tmp = arr[i]
			arr[i] = arr[r]
			arr[r] = tmp
		}
		arr = arr.map((song) => {
			song.path = song.itj.replace("100x100", "160x160")
			const url = song.itl
			song.url = (url.indexOf("?") !== -1) ? url + "&at=10lsGw" : url + "?at=10lsGw"
			song.title = song.itt
			song.albumTitle = song.itb
			song.artistName = song.itn
			const valueString = "" + Math.round(song.value * 1000) / 1000 * 100
			song.valueString = valueString.substr(0, Math.min(4, valueString.length))
			return song
		})
		const count = arr.length

		setTracks(oldTracks => ({
			...oldTracks, 
			tunes: arr, 
			trackAmount: count,
			currentSong: count !== 0 ? arr[0] : null, 
			nextSong: count > 1 ? arr[1] : null 
		}))
	}

	const isInViewport = (offset = 0) => {
		if (!containerRef.current) return false;
    	const top = containerRef.current.getBoundingClientRect().top
    	return (top + offset) >= 0 && (top - offset) <= window.innerHeight
  	}

	const startNextTrackInterval = () => {
		if (intervalId.current !== -1) return;
		if (tracks.trackAmount < 2) return;
		intervalId.current = setInterval(nextTrack, 4000)
	}

	const nextTrack = () => {
		stopNextTrackInterval()
		setTracks(oldTracks => ({
			...oldTracks, 
			startTween: true
		}))
	}

	const finishNextTrack = () => {
		currentTrackIndex.current = currentTrackIndex.current + 1
		if (currentTrackIndex.current === tracks.trackAmount) {
			currentTrackIndex.current = 0
		}
		let nextTrackIndex = currentTrackIndex.current + 1
		if (nextTrackIndex === tracks.trackAmount) {
			nextTrackIndex = 0
		}

		setTracks(oldTracks => ({
			...oldTracks, 
			currentSong: (oldTracks.trackAmount !== 0) ? oldTracks.tunes[currentTrackIndex.current] : null, 
			nextSong: (oldTracks.trackAmount > 1) ? oldTracks.tunes[nextTrackIndex] : null,
			startTween: false,
			count: oldTracks.count + 1
		}))
	}

	const stopNextTrackInterval = () => {
		if (intervalId.current !== -1) {
			clearInterval(intervalId.current)
			intervalId.current = -1
		}
	}

	return (tracks.tunes.length === 0) ? null :
		(
			<div id="latestTrack" ref={containerRef}>
				<div className="titleRow"><img src={music} alt="♫" />Analyzed Tracks</div>
				<AnalizedTracks.Provider value={[tracks]}>
					<Tunes finishNextTrack={finishNextTrack} />
				</AnalizedTracks.Provider>
			</div>
		)
}

export default Analyzed
