import React, { Component } from 'react'

import appstore from '../img/appstore.svg'
import exclamation from '../img/exclamation-triangle-solid.svg'
import parse from 'html-react-parser'
import LazyImageWrapper from './LazyImageWrapper'
import LazyBackground from './LazyBackground'
import Analyzed from './Analyzed/Analyzed'
import TextParser from './TextParser'

class Top extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            height: 0, 
            width: 0, 
            pixelRatio: 1, 
            viewInitialized: false, 
            scrollTop: 0
        }
        this.initialized = false
        this.topScreenContainer = React.createRef()
        this.bgBlack = React.createRef()
        this.intro = React.createRef()
    }
    componentDidMount() {
        if (!this.initialized) {
            window.addEventListener("resize", this.updateDimensions)
            window.addEventListener('scroll', this.updateScroll)
            this.initialized = true
        }
        if (this.state.viewInitialized === false) {
            this.setState({
                viewInitialized: true, 
                height: window.innerHeight, 
                width: window.innerWidth,
                pixelRatio: window.devicePixelRatio || 1
            })
        }
    }
    updateDimensions = () => {
        this.setState({
            height: window.innerHeight, 
            width: window.innerWidth,
            pixelRatio: window.devicePixelRatio || 1
        })
        console.log("updateDimensions")
    }
 
    componentWillUnmount() {
        console.log("componentWillUnmount top")
        window.removeEventListener("resize", this.updateDimensions)
        window.removeEventListener('scroll', this.updateScroll)
    }
    
    getScrollTop = () => {
        return document?.documentElement?.scrollTop || document?.body?.scrollTop || window?.pageYOffset || 0
    }

    updateScroll = (event) => {
        this.setState({ scrollTop: this.getScrollTop() })
    }

    getTextFromIntl = (key) => {
        return this.props.intl.formatMessage({ id: key })
    }

    render() {
        const intl = this.getTextFromIntl
        const isJP = this.props.intl.locale === 'ja'
        const langPathJp = isJP ? 'jp/' : ''

        const scrollTop = this.state.scrollTop
        const sy = scrollTop / 4
        let yy = 0
        if (this.intro.current && this.bgBlack.current) {
            let containerY = this.intro.current.offsetTop + this.intro.current.offsetHeight
            let bgh = this.bgBlack.current.offsetHeight
            let offsetToBgBlackCenterY = (containerY + bgh / 2) - this.state.height / 2
            let centerTop = bgh / 2 -offsetToBgBlackCenterY / 4
            yy = centerTop
        }
        else {
            yy = 10000
        }
        const topCss = parseInt(yy + sy)
        const deviceImageStyle = {
            top: topCss, 
            fallbacks: [
                { top: '-moz-' + topCss },
                { top: '-webkit-' + topCss },
                { top: '-o-' + topCss }
            ]
        }

        return (<>
<div id="container">
	<div id="main">
        <LazyBackground element="div" id="topImage" placeholder="/images/main_low.jpg" 
            bgSrc={ this.state.pixelRatio === 1 ? "/images/main.jpg" : "/images/main@2x.jpg" } />
        <LazyBackground element="h2" id="topTitle" placeholder="/images/toplogo.png" bgClassName="topTitleBg" 
            bgSrc={ this.state.pixelRatio === 1 ? "/images/toplogo.png" : "/images/toplogo@2x.png"}>NEW MONAURAL</LazyBackground>
		<div id="intro" ref={this.intro}>
			<p className="copy"><span className="quote">“</span>Make Monaural Sound New<span className="quote">”</span></p>
			<h2 className={isJP ? "catch jp" : ""}>{parse(intl('top.catch'))}</h2>
			<a href={`https://itunes.apple.com/${langPathJp}app/id1439882679?mt=8`} id="appstore"><img src={appstore} alt="Download on the App Store" /></a>
            <p className="requirements">iOS 11 or later, FREE</p>
		</div>
        <LazyBackground element="div" id="topScreenContainer" 
            bgSrc={ this.state.pixelRatio === 1 ? "/images/bg_artist.jpg" : "/images/bg_artist@2x.jpg" }>
            <div id="bgGray" />
            <div id="bgBlack" ref={this.bgBlack}>
                <LazyImageWrapper src="/images/device_low.jpg" srcSet="/images/device.jpg 1x, /images/device@2x.jpg 2x" 
                id="deviceImage" style={deviceImageStyle} alt="screenshots" />
            </div>
            <div className="screenshots" id="topScreen" ref={this.topScreen}>
                <div className="screen">
                    <h3>
                    { isJP ? 
                        <LazyImageWrapper src="/images/screen1_low.jpg" srcSet="/images/screen1.jpg 1x, /images/screen1@2x.jpg 2x" 
                            alt={intl('top.playback.alt')} /> : 
                        <LazyImageWrapper src="/images/screen1_us_low.jpg" srcSet="/images/screen1_us.jpg 1x, /images/screen1_us@2x.jpg 2x" 
                            alt={intl('top.playback.alt')} />
                    }
                    </h3>
                </div>
                <div className="description">
                    <h4 className="feature">{parse(intl('top.playback.title'))}</h4>
                    <p className={isJP ? "" : "en"}>{parse(intl('top.playback.text'))}</p>
                </div>
            </div>
        </LazyBackground>
        <LazyBackground element="div" id="secondScreenContainer" 
            bgSrc={ this.state.pixelRatio === 1 ? "/images/bg_lr.jpg" : "/images/bg_lr@2x.jpg" }>
            <div className="screenshots" id="secondScreen">
                <div className="screen">
                    <h3>
                        <LazyImageWrapper src="/images/screen2_low.jpg" srcSet="/images/screen2.jpg 1x, /images/screen2@2x.jpg 2x" 
                            alt={intl('top.lr.alt')} />
                    </h3>
                </div>
                <div className="description">
                    <h4 className="feature">{parse(intl('top.lr.title'))}</h4>
                    <p className={isJP ? "" : "en"}>{parse(intl('top.lr.text'))}</p>
                </div>
            </div>
        </LazyBackground>
        <LazyBackground element="div" id="thirdScreenContainer"  
            bgSrc={ this.state.pixelRatio === 1 ? "/images/bg_fes.jpg" : "/images/bg_fes@2x.jpg" }>
            <div className="screenshots" id="thirdScreen">
                <div className="screen">
                    <h3>
                    { isJP ? 
                        <LazyImageWrapper src="/images/screen3_low.jpg" srcSet="/images/screen3.jpg 1x, /images/screen3@2x.jpg 2x" 
                            alt={intl('top.analytics.alt')} /> : 
                        <LazyImageWrapper src="/images/screen3_us_low.jpg" srcSet="/images/screen3_us.jpg 1x, /images/screen3_us@2x.jpg 2x" 
                            alt={intl('top.analytics.alt')} />
                    }
                    </h3>
                </div>
                <div className="description">
                    <h4 className="feature">{parse(intl('top.analytics.title'))}</h4>
                    <p className={isJP ? "" : "en"}>{parse(intl('top.analytics.text'))}</p>
                    <div id="latestTrackContainer">
                        <Analyzed />
                    </div>
                </div>
            </div>
        </LazyBackground>
    </div>
</div>
<div id="info">
	<h3><img src={exclamation} className="inlineIcon" alt="Notice" />{parse(intl('top.notice.title'))}</h3>
	<ul>
		<li><TextParser intl={this.props.intl} translationKey="top.notice.text0" /></li>
		<li><TextParser intl={this.props.intl} translationKey="top.notice.text1" /></li>
	</ul>
</div>
        </>)
    }
}

export default Top
