import React, { useContext } from 'react'
import { AnalizedTracks } from './Analyzed'
import icon from '../../img/icon_lr2.svg'

function Tune(props) {

	const [tracks] = useContext(AnalizedTracks)

	return (
		(!tracks[props.targetTrackKey]) ?
			null
		: 
		(
		<a href={tracks[props.targetTrackKey].url}>
			<div className="track">
				<div className="thumb"><img src={tracks[props.targetTrackKey].path} alt="album jacket thumbnail" /></div>
				<div className="trackInfo">
					<p>{tracks[props.targetTrackKey].title}</p>
					<p>{tracks[props.targetTrackKey].albumTitle} - {tracks[props.targetTrackKey].artistName}</p>
					<p>View on iTunes</p>
				</div>
				<div className="analyses">
					<img src={icon} alt="L=R" /><p>{tracks[props.targetTrackKey].valueString}<span>%</span></p>
				</div>
			</div>
		</a>
		)
	)
}

export default Tune
