import React, { Component } from 'react'
import { Location } from '@reach/router';
import Top from '../components/Top'
import SiteHeader from '../components/SiteHeader'
import SiteFooter from '../components/SiteFooter'
import SEO from '../components/SEO'
import { injectIntl } from "gatsby-plugin-intl"

class index extends Component {
    constructor(props, context) {
        super(props, context)
    }
    render() {
        return (
            <Location>
                {locationProps => (
                    <>
                    <SEO title={this.props.intl.formatMessage({ id: 'top.title' })} className="top" {...locationProps} isTop />
                    <SiteHeader {...locationProps} {...this.props} />
                    <Top {...locationProps} {...this.props} />
                    <SiteFooter {...locationProps} {...this.props} />
                    </>
                )}
            </Location>
        )
    }
}

export default injectIntl(index)
